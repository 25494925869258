/** @jsx jsx */
import { Image, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";
import { helpPackageData } from "../../data/donation-packages";
import bannerImg from "../../static/imgs/help-donation.png";

const HeartfulnessExperienceLifePotential = () => {
  const packageData = helpPackageData;

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers(
        "Heartfulness – Experience Life’s Potential",
        event
      ),
    []
  );

  const treePackage =
    '<div class="container"><div class="tree_package"><donation_package type=both/></div></div><div class="container"><div id="tree_package" class="col-12"><gatsby_donation amount="2" btntext="Contribute Now" donationid="12" projectid="" btnbg="#4d8f15" btnstyle="margin:2px;margin-left:auto;margin-right:auto;width:auto;border-radius:25px;padding-left:25px;padding-right:25px;" colortext="black" colorprimary="forestgreen" colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6" colorhighlight="#efeffe" titletext="Donation for HET" rate="50" showinput="" enablesigninoption="1"/></div>';

  return (
    <>
      <LayoutHome seoTitle="Heartfulness Experience Life's Potential (HELP) -India – Donations">
        <div
          sx={{
            padding: "40px 0px",
            backgroundColor: "#f7f7f7",
            marginBottom: "30px",
          }}
        >
          <div className="container">
            <h1
              className="text-center"
              sx={{ fontSize: "5.6em", fontWeight: "600" }}
            >
              <span sx={{ color: "#1da1f2" }}>H</span>
              <span sx={{ color: "#fe9717" }}>E</span>
              <span sx={{ color: "#a1df22" }}>L</span>
              <span sx={{ color: "#f67766" }}>P</span>
            </h1>
            <h3
              className="text-center"
              sx={{ fontSize: "1.6em", fontWeight: "600" }}
            >
              <span sx={{ color: "#1da1f2" }}>H</span>eartfulness –{" "}
              <span sx={{ color: "#fe9717" }}>E</span>xperience{" "}
              <span sx={{ color: "#a1df22" }}>L</span>ife’s{" "}
              <span sx={{ color: "#f67766" }}>P</span>otential
            </h3>
          </div>
        </div>
        <div className="container">
          <div sx={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
              }}
              src="https://www.youtube.com/embed/ro3Ji5pdHpk?controls=0"
              name="fitvid0"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              title="Heartfulness Experience Lifes Potential Help"
            />
          </div>
        </div>
        &nbsp;
        <div className="container">
          <h3 className="text-center" sx={{ fontSize: "1.6em" }}>
            Inviting Donors/ Sponsors/ Funding-Partners
          </h3>
          <p
            className="text-center"
            sx={{ fontSize: "19px", marginTop: "20px" }}
          >
            To join hands with Heartfulness Education Trust (HET) towards
            imparting ‘life-skills program’ for 10 lakh intermediate college
            students across 13 districts in Andhra Pradesh (in association with
            Board of intermediate education, Government of AP)
          </p>
          <p className="text-center">
            <Image src={bannerImg} />
          </p>
          <p className="text-center" sx={{ fontSize: "18px" }}>
            For more details reach out to:
            <br /> Bharath Madhavan (
            <a href="mailto:bharath.madhavan@heartfulness.org">
              bharath.madhavan@heartfulness.org
            </a>
            )
          </p>
        </div>
        &nbsp;
        <div className="container" sx={{ fontSize: "18px" }}>
          <b sx={{ fontSize: "18px", color: "#333" }}>DONATE NOW:</b>
          <br />
          <br />
          <b>HELP:</b> Training on Essential Life-Skills with Heartfulness
          Meditation for 3 lakh currently,and 10 lakh students in 2019 in Andhra
          Pradesh in association with Board of Intermediate Education,
          Government of Andhra Pradesh. Sponsoring cost, 50/- per student only.
          Sponsors can select a set of students and donate accordingly.
        </div>
        <div className="container">
          <div className="tree_package" sx={{ marginTop: "15px" }}>
            <PageContent
              eventCallback={eventCallback}
              pageContent={treePackage}
              packageData={packageData}
            />
          </div>
        </div>
      </LayoutHome>
    </>
  );
};

export default HeartfulnessExperienceLifePotential;
